import * as React from 'react';
import { Product } from '../domain/interfaces';
import {connect} from "react-redux"
import {addProductToBasket} from "../usecases/basket"

import {
    Svg
} from './svgIcons.js'


const ProductMiniature:React.FunctionComponent<{product: Product, dispatch?:any}> = ({product, dispatch}) => {

    const onAddProductToBasket = () => {
      dispatch(addProductToBasket(product.id))
    }

    return <div className="w-1/2 md:w-1/4 cursor-pointer group product-card mt-4 md:mt-10 mx-0 px-4">
      <div className="w-full h-64 overflow-hidden relative">
      <a href={`/product/${product.slug}`}>
        <div className="w-full h-64 bg-contain bg-center bg-no-repeat p-5 group-hover:filter-zoom-in transition duration-500 ease-in-out" style={{'backgroundImage': `url(${product.coverImage.thumbnail.imgUrl})`}} >
            <div className="tooltip absolute flex flex-row" style={{'top':'1.5rem','right':'1.5rem'}}>
              <div className="tooltip-text self-center px-4 mr-2 bg-brand-primary-darker text-white text-sm trigger-hover-display"> aperçu </div>
              <div  className="w-8 p-1 bg-white border border-brand-primary-darker rounded-full bg-opacity-20 hover:bg-opacity-100 opacity-0 group-hover:opacity-100 transition duration-200 ease-in-out hover:bg-brand-primary-darker text-brand-primary-darker hover:text-white"> 
                <Svg icon='eye'/>
              </div>
            </div>
        </div>
      </a>
      </div>
      <div className="text-brand-gray-light mt-1 h-12 md:h-auto"> 
        {product.priceBeforePromo && <span className="text-white font-bold p-1 mb-1 rounded bg-brand-primary-darker text-sm"> PROMO <br/> </span> }
        <a href={`/product/${product.slug}`}> {product.name} </a>
      </div>
      <div> 
        {product.priceBeforePromo && <span className="font-bold text-sm line-through mr-2 text-gray-500"> {product.priceBeforePromo}{product.currencySymbol} TTC </span> }
        <span className="font-bold"> {product.priceWithTaxFormat} {product.currencySymbol} </span> 
        <span className="text-xs"> TTC </span>
      </div>
      { product.stockAvailable > 0 && <div onClick={onAddProductToBasket}  className="md:hidden w-full flex-row mt-2 group flex"> 
          <div className="self-center w-8 p-2 border border-brand-primary-darker rounded-full transition duration-200 ease-in-out bg-brand-primary-darker text-white"> <Svg icon='shopping-bag'/> </div>
          <div className="pl-2 self-center text-brand-primary"> Ajouter au panier  </div>
      </div> }
      { product.stockAvailable <= 0 && <div className="md:hidden w-full flex-row mt-2 group flex"> 
          <div className="self-center w-8 p-2 border rounded-full transition duration-200 ease-in-out bg-gray-300 text-white"> <Svg icon='shopping-bag'/> </div>
          <div className="pl-2 self-center text-brand-gray-light">Victime de son succès  </div>
      </div> }
      <div className="hidden md:block">
          { product.stockAvailable <= 0 && <div className="w-full flex-row mt-2 group flex basket-button"> 
              <div className="self-center w-8 p-2  border rounded-full transition duration-200 ease-in-out bg-gray-300 text-white"> <Svg icon='shopping-bag'/> </div>
              <div className="pl-2 self-center  text-brand-gray-light">  Victime de son succès </div>
            </div>
          }
          { product.stockAvailable > 0 && <div onClick={onAddProductToBasket} className="w-full flex-row mt-2 group flex basket-button"> 
            <div className="self-center w-8 p-2 bg-white border border-brand-primary-darker text-brand-primary-darker rounded-full transition duration-200 ease-in-out hover:bg-brand-primary-darker hover:text-white"> <Svg icon='shopping-bag'/> </div>
            <div className="pl-2 self-center  group-hover:text-brand-primary">  Ajouter au panier  </div>
          </div> }
      </div>
    </div>
}

export default connect(state => ({
  state
}), null)(ProductMiniature)