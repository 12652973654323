import * as React from 'react';
import {BlogCardView} from './interface'

import {
    Svg
} from './svgIcons.js'

const BlogCard: React.FunctionComponent<{card: BlogCardView}> = ({card}) => (
    <div className="w-full md:w-1/3 group overflow-hidden px-4">
      <div className="overflow-hidden h-64">
        <a href={'/blog/'+card.slug}>
        <div className="cursor-pointer bg-cover w-full h-64 object-cover bg-center group-hover:filter-zoom-in group-hover:filter-lighter transition duration-500" style={{'backgroundImage': `url(${card.imgUrl})`}} />
        </a>
      </div>
      <div className="w-full flex flex-row mt-4 px-2">
        <div className="w-1/3">
          <div className="h-16 w-16 flex justify-center rounded-full bg-white ring-2 ring-gray-300 bg-white hover:bg-black text-black hover:text-white cursor-pointer">
              <div className="self-center text-center">
                <div className="text-2xl"> {card.date} </div> 
                <div className="text-md -mt-1" > {card.month.slice(0, 3)}. </div>
              </div>
          </div>
        </div>
        <div className="w-2/3">
          <div className="text-xl"> <a href={'/blog/'+card.slug} className="text-brand-primary-darkest font-bold hover:text-brand-gray-light cursor-pointer"> {card.title} </a> </div>
          <div className="w-1/3 opacity-0 group-hover:opacity-100 border-t-2 border-black pt-1 transition duration-500" />
          <div className="text-gray-800"> {card.preview} </div>
        </div>
      </div>
    </div>
)
  
export default BlogCard
