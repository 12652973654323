import React, {useContext, useEffect, useState} from "react"
import { httpApiGateway } from "../../adapters/secondary/httpEshopApi";
import { BlogPost, CategoryTree, Product, StaticContext } from "../../domain/interfaces";
import CategoryPage from "../../templates/categorypage";
import ProductPage from "../../templates/productpage"

export default function Home(props: any) {

  const categorySlug = props.params.slug
  const api = new httpApiGateway()

  const [context, setContext] = useState<StaticContext | null>(null)
  const [category, setCategory] = useState<CategoryTree| null>(null)

  useEffect(() => {
    api.retrieveStaticContext().then(setContext)
    api.retrieveCategory(categorySlug).then(setCategory)
  }, []) // given empty array useEffect run only once

  return context && category && <CategoryPage pageContext={{
      staticContext: context,
      category: category,
      products: []
  }}/>
}
