import * as React from 'react';
import { Product } from '../domain/interfaces';
import ProductMiniature from './ProductMiniature';

const Loader = () => (
    <div className="spinner mx-auto">
        <div className="double-bounce1"></div>
        <div className="double-bounce2"></div>
    </div>
)

const ProductBannerWrapper: React.FunctionComponent<{title?: string, titleHtml?: string, children:React.ReactNode, descriptionHtml?: string}> = ({title, titleHtml, children, descriptionHtml}) => (
    <div className="w-full max-w-screen-lg flex flex-col mx-auto pt-10 md:px-5">
          { titleHtml && <div className="w-full text-xl md:text-2xl border-b border-brand-gray-lightest pb-0 md:pb-2 pl-2 md:ml-0" dangerouslySetInnerHTML={{ __html: titleHtml }} ></div> }
          { title && <div className="w-full text-xl md:text-2xl border-b border-brand-gray-lightest pb-0 md:pb-2 pl-2 md:ml-0"> {title} </div> }
          { descriptionHtml && <div className="pl-5 mt-2 text-md text-gray-700" dangerouslySetInnerHTML={{ __html: descriptionHtml }}></div> }
          <div className="w-full flex flex-row flex-wrap mt-1 md:mt-10">
            {children}
          </div>
    </div>
)
  
const ProductBanner: React.FunctionComponent<{banner: {title?: string, titleHtml?: string, descriptionHtml?: string, products: Product[] | 'loading'}}> = ({banner}) => (
<ProductBannerWrapper titleHtml={banner.titleHtml} title={banner.title} descriptionHtml={banner.descriptionHtml}>
    {
    banner.products == 'loading' ?  <Loader /> : banner.products.map(product => 
        <ProductMiniature key={product.id} product={product} />)
    }
</ProductBannerWrapper>
)

export default ProductBanner