import * as React from 'react';
import BlogCard from './BlogCard'

import {BlogCardBannerView} from './interface'

const BlogCardsWrapper: React.FunctionComponent<{children: React.ReactNode}> = ({children}) => (
    <div className="w-full max-w-screen-lg flex flex-col mx-auto pt-10 px-5 mb-5">
      <div className="w-full text-2xl border-b border-brand-gray-lightest pb-2"> Nos derniers articles </div>
      <div className="w-full flex flex-col md:flex-row flex-wrap mt-10 space-y-5 md:space-y-0">
        {children}
      </div>
    </div>
)
  
const BlogCardBanner: React.FunctionComponent<{banner: BlogCardBannerView}> = ({banner}) => {
    const postElements = banner.cards.map((card) => <BlogCard key={card.title} card={card}/>)
    return (<BlogCardsWrapper> {postElements} </BlogCardsWrapper>)
}

export default BlogCardBanner