import React, {useEffect, useState} from "react"
import { CategoryNode, CategoryTree, Product, StaticContext } from "../domain/interfaces"
import {Svg} from "../components/svgIcons"
import HeaderMenu from "../components/Header"
import HomeCarousel from "../components/HomeCarousel"
import HomeBanner from "../components/HomeBanner"
import { Helmet } from "react-helmet"
import ProductMiniatureBanner from "../components/ProductMiniatureBanner"
import TestimonialCarousel from "../components/TestimonialCarousel"
import BlogCardBanner from "../components/BlogCardBanner"
import Footer from "../components/Footer"
import { httpApiGateway } from "../adapters/secondary/httpEshopApi"

const ApiGateway = new httpApiGateway()

const CategoryPage: React.FunctionComponent<{pageContext: {staticContext: StaticContext, products: Product[], category: CategoryTree}}> = ({pageContext}) => {

    const {staticContext, category} = pageContext
    
    // update products list asynchronously 
    const [products, setProducts] = useState<Product[] | 'loading'>('loading')
    useEffect(() => {
        ApiGateway.retrieveProducts(parseInt(category.node.id)).then(setProducts)
    }, [])

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Chamantinis Bijoux à la Réunion | {category.node.label} </title>
            </Helmet>
            <HeaderMenu contact={staticContext.contact} menu={staticContext.categories}/>
            <ProductMiniatureBanner banner={{titleHtml: category.node.label, products, descriptionHtml: category.node.htmlDescription}}/>
            { products && products.length === 0 && (<div className="w-full max-w-screen-lg mx-auto pl-20"> Il n'y a pas de produits dans cette catégorie </div>)}
            <BlogCardBanner banner={{title: "Nos derniers articles", cards: staticContext.blogPosts}} />
            <Footer contact={staticContext.contact} group1={staticContext.footer.group1} group2={staticContext.footer.group2} group3={staticContext.footer.group3} />
        </div>
    )

}

export default CategoryPage